.metismenu {
  background: #1F303A;
  font-size: 1.1em;
  overflow: hidden;
  position: relative;
}
.metismenu > .metismenu-container > .metismenu-item > .metismenu-link {
  line-height: 3.0em;
}
.metismenu > .metismenu-container > .metismenu-item > .metismenu-link .metismenu-state-icon {
  line-height: 3.0em;
}
.metismenu::after {
  box-shadow: 0 0 0.4em rgba(0, 0, 0, 0.5) inset;
  -webkit-box-shadow: 0 0 0.4em rgba(0, 0, 0, 0.5) inset;
  content: " ";
  pointer-events: none;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}
.metismenu-container,
.metismenu-item {
  margin: 0;
  padding: 0;
}
.metismenu-container {
  list-style: none;
}
.metismenu-container .metismenu-container {
  box-shadow: 0 0.3em 0.3em -0.3em #0c0d0e inset, 0 -0.3em 0.3em -0.3em #0c0d0e inset;
  -webkit-box-shadow: 0 0.3em 0.3em -0.3em #0c0d0e inset, 0 -0.3em 0.3em -0.3em #0c0d0e inset;
  transition: padding 300ms;
  -webkit-transition: padding 300ms;
  background: rgba(255, 255, 255, 0.05);
}
.metismenu-container .metismenu-container .metismenu-item > .metismenu-link {
  height: 0;
  overflow: hidden;
}
.metismenu-container .metismenu-container .metismenu-link {
  padding-left: 1em;
}
.metismenu-container .metismenu-container .metismenu-container .metismenu-link {
  padding-left: 2em;
}
.metismenu-container.visible {
  padding: .5em 0;
}
.metismenu-container.visible > .metismenu-item > .metismenu-link {
  height: 2.5em;
  font-size: 0.9em;
}
.metismenu-link {
  color: #A8B5BD;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.5);
  -webkit-text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.5);
  transition: height 300ms, color 300ms, background-color 300ms;
  -webkit-transition: height 300ms, color 300ms, background-color 300ms;
  display: block;
  line-height: 2.5em;
  text-decoration: none;
}
.metismenu-link:hover {
  background: rgba(255, 255, 255, 0.05);
  color: #F7F7F7;
}
.metismenu-link.active {
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  -webkit-text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background: #224559;
  color: #FFFFFF;
}
.metismenu-link.has-active-child {
  color: #FFFFFF;
}
I.metismenu-icon {
  text-align: center;
  width: 3em;
}
I.metismenu-state-icon {
  transition: transform 300ms;
  -webkit-transition: transform 300ms;
  float: right;
  line-height: 2.5em;
  text-align: center;
  width: 3em;
}
I.metismenu-state-icon.rotate-minus-90 {
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
}

.md-list {
  margin-right: 32px;
}

/** CSS for Menu Icon */
.icon-stream-list:before {
    content : url(../images/stream.png);
    padding: 10px;
}
.icon-add-stream:before {
    content : url(../images/add_stream.png);
    padding: 10px;
}
.icon-setting:before {
    content : url(../images/setting.png);
    padding: 10px;
}
.icon-upload-repo:before {
    content : url(../images/upload_repo.png);
    padding: 10px;
}
.icon-logs:before {
    content : url(../images/logs.png);
    padding: 10px;
}
.icon-monitoring:before {
    content : url(../images/monitoring.png);
    padding: 10px;
    text-align:center;
}
.icon-power:before {
    content : url(../images/power.png);
    padding: 10px;
}
.icon-sdk-ui:before {
    content : url(../images/monitoring.png);
    padding: 10px;
}
.icon-help-menu:before {
    content : url(../images/help.png);
    padding: 10px;
}
