body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.1em;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.md-selection-control-label{
  font-size: 1.0em !important;
}


.schedule-hours{
    margin-left: 15px !important;
    margin-right: 30px !important;
    width: 60px !important;
}

.schedule-minutes{
    margin-left: 15px !important;
    margin-right: 30px !important;
    width: 60px !important;
}

.schedule-seconds{
    margin-left: 15px !important;
    width: 60px !important;
}

.schedule-weekday{
    margin-left: 40px !important;
    padding: 0px !important;
}

.center-text{
    text-align: center;
}

.margin-left-25{
    margin-left: 25px;
}

.stream_list_running,
.stream_list_disabled,
.stream_list_deleted,
.stream_list_recovering,
.stream_list_desired,
.stream_list_failed,
.stream_list_paused,
.stream_list_stopped {
    display: block;
    width: 100%;
    height: 10px;
}

.stream_list_running {
    background-color: green;
}

.stream_list_disabled,
.stream_list_stopped,
.stream_list_deleted {
    background-color: gray;
}

.stream_list_desired,
.stream_list_recovering {
    background-color: orange;
}
.stream_list_failed {
    background-color: red;
}

.stream_list_paused {
    background-color: #0097f1;
}

.running-sdk,
.disabled-sdk,
.stopped-sdk,
.deleted-sdk,
.recovering-sdk,
.desired-sdk,
.paused-sdk,
.failed-sdk {
    font-weight: bold;
}

.running-sdk {
    color: green;
}

.disabled-sdk,
.stopped-sdk,
.deleted-sdk {
    color: gray;
}

.desired-sdk,
.recovering-sdk {
    color: orange;
}

.failed-sdk {
    color: red;
}
.paused-sdk {
    color: #0097f1;
}

.running,
.disabled,
.stopped,
.deleted,
.recovering,
.desired,
.paused,
.failed {
    margin-right: 10px;
    font-weight: bold;
}

.running {
    color: green;
}

.disabled,
.stopped,
.deleted {
    color: gray;
}

.desired,
.recovering {
    color: orange;
}

.failed {
    color: red;
}
.paused {
    color: #0097f1;
}
.parameters {
    display: block;
    height: 100px;
    width: 500px;
    overflow-y: scroll;
    padding: 5px;
    margin-top: 10px;
    border: 1px solid #d3d3d38a;
}

.hide {
    display: none;
}

.refresh-interval {
    float: right;
}

.refresh-interval > label {
    margin-top: 3px;
    margin-right: 10px;
    margin-left: 20px;
}

.refresh-interval > select {
    width: 75px !important;
}

.small-button {
    padding: 2px !important;
    margin: 2px 5px !important;
    height: 22px !important;
    font-size: 0.7em !important;
    width: 40px;
}

.show-button {
    padding: 2px !important;
    margin: 2px 5px !important;
    height: 30px !important;
    font-size: 0.7em !important;
    width: 100px;
}

input:read-only {
  background-color: #f2f2f2;
}

.menu{
    height: 100vh;
    overflow-y: scroll;
    scrollbar-width: none;
    background-color: #1f303a;
}

.menu::-webkit-scrollbar {
    display: none;
}

.contents {
    position:relative;
    height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
}

.loading-msg {
   padding: 50px;
   text-align: center;
}

.no_img {
    opacity:0.5;
}

.running_img {
    opacity: 1.0;
}
.link{
    color: #005781;
    font-weight: bold;
    cursor: pointer;
    text-decoration: underline;
}
.slider-time-dropdown{
    margin-left: 4px !important;
    margin-right: 6px !important;
    padding: 2px !important;
    width: 40px !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
    width: 50px;
    height: 32px;
    margin-right:-10px;
}
.ftp-fixed-input {
    position: absolute;
    display: block;
    left: 9px;
    top: 15px;
    color: #404040;
}
.__react_component_tooltip {
    max-width: 15vw;
}

.md-table-column--header {
    font-size: 1.1em !important;
    background-color: #f5f5f5 !important;
    color: #000000 !important;
}
.swal-title {
    font-size: 20px;
}
.refresh-img {
    cursor: pointer !important;
    opacity: 0.7;
    height: auto;
    padding-top: 10px;
}
.refresh-img:hover {
    opacity: 1;
    cursor: pointer !important;
}
.showPassword{
    -webkit-text-security:disc;
}
.hidePassword{
    -webkit-text-security:none;
}
.videoInsert {
    object-fit: fill;
}
.reload-button {
    margin: 2px 5px;
    background-color: #0097f1;
    color: #f2f2f2;
    width: auto;
    border: none;
    padding: 7px;
    border-radius: 5px;
}
.reload-button:hover {
    background-color: rgb(0, 162, 255);
    cursor: pointer;
  }
  .marker {
    transform: perspective(40px) rotateX(20deg) rotateZ(-45deg);
    transform-origin: 50% 50%;
    border-radius: 50% 50% 50% 0;
    padding: 0 3px 3px 0;
    width: 40px;
    height: 40px;
    background: #ed1f34;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -2.2em 0 0 -1.3em;
    -webkit-box-shadow: -1px 1px 4px rgba(0, 0, 0, .5);
    -moz-box-shadow: -1px 1px 4px rgba(0, 0, 0, .5);
    box-shadow: -1px 1px 4px rgba(0, 0, 0, .5);
  }
  
  .marker:after {
    content: '';
    width: 1em;
    height: 1em;
    margin: 1em 0 0 .7em;
    background: #ffffff;
    position: absolute;
    border-radius: 50%;
      -moz-box-shadow: 0 0 10px rgba(0, 0, 0, .5);
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, .5);
    box-shadow: 0 0 10px rgba(0, 0, 0, .5);
    -moz-box-shadow: inset -2px 2px 4px hsla(0, 0, 0, .5);
    -webkit-box-shadow: inset -2px 2px 4px hsla(0, 0, 0, .5);
    box-shadow: inset -2px 2px 4px hsla(0, 0, 0, .5);
  }
  .beacon {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 3em;
    width: 3em;
    border-radius: 50%;
    -webkit-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    z-index: -1
  }
  
  .beacon:before,
  .beacon:after {
    position: absolute;
    content: "";
    height: 3em;
    width: 3em;
    left: 0;
    top: 0;
    background-color: transparent;
    border-radius: 50%;
    box-shadow: 0 0 0 3px #ed1f34;
    -webkit-animation: active 2s infinite linear;
    -moz-animation: active 2s infinite linear;
    animation: active 2s infinite linear;
  }
  
  .beacon:after {
    -moz-animation-delay: 1s;
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
  }
  
  @-webkit-keyframes active {
    0% {
      -webkit-transform: scale(.1);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(2.5);
      opacity: 0;
    }
  }
  
  @keyframes active {
    0% {
      transform: scale(.1);
      transform: box-shadow(0px 0px 0px 1px #ed1f34);
      opacity: 1;
    }
    100% {
      transform: scale(2.5);
      opacity: 0;
    }
  }
  .loader {
    border: 2px solid #f3f3f3; /* Light grey */
    border-top: 5px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 15px;
    height: 15px;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .spinner-1 {
    border: 2px solid #f3f3f3; /* Light grey */
    border-top: 2px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 15px;
    height: 15px;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  #btndisabled[disabled]{
   opacity: 0.3;
  }

  #map {
    width: 100vw;
    height: 100vh;
  }
  
  .control-panel {
    position: absolute;
    top: 0;
    right: 0;
    max-width: 320px;
    background: #fff;
    box-shadow: 0 2px 4px rgba(0,0,0,0.3);
    padding: 12px 24px;
    margin: 20px;
    font-size: 13px;
    line-height: 2;
    color: #6b6b76;
    text-transform: uppercase;
    outline: none;
  }
